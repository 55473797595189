<template>
  <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.4688 0.408572L0.40907 6.46841C0.268803 6.60856 0.191528 6.79566 0.191528 6.99516C0.191528 7.19465 0.268803 7.38175 0.40907 7.52191L0.855224 7.96817C1.14594 8.25856 1.61845 8.25856 1.90872 7.96817L6.99732 2.87958L12.0916 7.97382C12.2318 8.11398 12.4188 8.19136 12.6182 8.19136C12.8178 8.19136 13.0048 8.11398 13.1452 7.97382L13.5912 7.52755C13.7315 7.38729 13.8088 7.2003 13.8088 7.0008C13.8088 6.80131 13.7315 6.61421 13.5912 6.47405L7.52595 0.408572C7.38524 0.268083 7.19737 0.19092 6.99765 0.191362C6.79716 0.19092 6.6094 0.268083 6.4688 0.408572Z" fill="#8B9DA5"/>
  </svg>
</template>
<script>

export default {
  props: {
    size: Number || null
  }
};
</script>
