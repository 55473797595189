export default [
  {
    path: "/fobidden",
    name: "403",
    component: () => import("../components/Fobidden.vue"),
    meta: {
      layout: "empty",
      requireLogin: false,
    }
  },
  // {
  //   path: "*",
  //   name: "404",
  //   component: () => import("../components/PageNotFound.vue"),
  //   meta: {
  //     layout: "empty",
  //     requireLogin: false,
  //   }
  // },
  {
    path: "/",
    component: {
      render: c => c("router-view")
    },
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("../views/Home.vue"),
        meta: {
          requireLogin: true,
        },
      },
      {
        path: "/mention",
        name: "Mention",
        component: () => import("../views/logs/Mention.vue"),
        meta: {
          requireLogin: true,
        }
      }, {
        path: "/guide",
        name: "Guide",
        component: () => import("../views/Guide.vue"),
        meta: {
          requireLogin: false,
        }
      },
      {
        path: "/no-reply",
        name: "NoReply",
        component: () => import("../views/logs/NoReply.vue"),
        meta: {
          requireLogin: true,
        }
      },
    ]
  },
  { path: "*", redirect: "/" },
  {
    path: "/projects",
    component: {
      render: c => c("router-view")
    },
    children: [
      {
        path: "/",
        name: "ListProject",
        component: () => import("../views/ListProject.vue"),
        meta: {
          requireLogin: true,
        },
      },
      {
        path: "create",
        name: "CreateProject",
        component: () => import("../views/projects/Create.vue"),
        meta: {
          requireLogin: true,
        },
      },
      {
        path: ":id",
        component: {
          render: c => c("router-view")
        },
        children: [
          {
            path: "edit",
            name: "EditProject",
            component: () => import("../views/projects/Edit.vue"),
            meta: {
              requireLogin: true,
            },
          },
          {
            path: "/",
            name: "ProjectDetail",
            component: () => import("../views/projects/Detail.vue"),
            meta: {
              requireLogin: true,
            },
          },
        ]
      },
    ]
  },
  {
    path: "/settings",
    component: {
      render: c => c("router-view")
    },
    children: [
      {
        path: "division",
        component: {
          render: c => c("router-view")
        },
        children: [
          {
            path: "/",
            name: "Division",
            component: () => import("../views/settings/Division.vue"),
            meta: {
              requireLogin: true,
            },
          },
          {
            path: ":id",
            name: "EditDivision",
            component: () => import("../views/settings/EditDivision.vue"),
            meta: {
              requireLogin: true,
            },
          },
        ]
      },
      {
        path: "bot",
        name: "Bot",
        component: () => import("../views/settings/Bot.vue"),
        meta: {
          requireLogin: true,
        },
      },
    ]
  }
];
