import * as api from "@/api/projects";
import service from "@/utils/axios";

export const actions = {
  async getProjectList(_, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_PROJECT_LIST,
        method: "GET",
        params
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
  async getProjectTeams(_, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_PROJECT_TEAMS(params.name),
        method: "GET",
        params
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
  async getProjectChannels(_, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_PROJECT_CHANNELS(params.name),
        method: "GET",
        params
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
  async createNewProject(_, body) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_PROJECT_LIST,
        method: "POST",
        data: body
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
  async getProjectDetail(_, id) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_PROJECT_DETAIL(id),
        method: "GET",
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
  async updateProject(_, body) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_PROJECT_DETAIL(body.id),
        method: "PUT",
        data: body
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
  async deleteProject(_, id) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_PROJECT_DETAIL(id),
        method: "DELETE",
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
};