import service from "@/utils/axios";
import Cookie from "js-cookie";
import * as api from "@/api/user";

export const state = {
  user: {},
  access_token: Cookie.get("token"),
};

export const getters = {
  user: state => state.user,
  token: state => state.access_token,
};

export const mutations = {
  setLogin(state, user) {
    state.user = user;
    Cookie.set("token", user.access_token, {expires: 2592000});
  },
  setAccountInfo(state, user) {
    state.user = user;
  },
};

export const actions = {
  async getInfoUser({commit}) {
    await service({
      url: api.GET_CURRENT_USER,
      method: "GET"
    }).then(response => {
      commit("setAccountInfo", response);
    });
  },


  async getInfoUserD() {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_CURRENT_USER,
        method: "GET",
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
};
