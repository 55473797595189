//eslint-disable-next-line no-unused-vars
import Cookies from "js-cookie";
import store from "@/store";

export default async function auth({ to, next }) {
  const token = Cookies.get("token");
  if (to.matched.some(record => record.meta.requireLogin)) {
    if (!token || token == "") {
      window.location.href = process.env.VUE_APP_ACMS_URL;
    } else {
      await store.dispatch("auth/getInfoUser");
      next();
    }
  } else {
    next();
  }
}
