import * as api from "@/api/user";
import service from "@/utils/axios";

export const actions = {
  async getUsersInChatWork(_, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_USER_CHATWORK(params.id),
        method: "GET",
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
  async getUserInTeams(_, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_USER_TEAMS(params.name),
        method: "GET",
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
  async me(_, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_CURRENT_USER,
        method: "GET",
        params
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
  async logout(_, body) {
    return new Promise((resolve, reject) => {
      service({
        url: `${process.env.VUE_APP_ACMS_API}/logout`,
        method: "POST",
        data: body
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
};