import Vue from "vue";
import App from './App.vue'
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import DateRangePicker from 'vue2-daterange-picker';
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import plugins from "./plugins";
import { ValidationObserver, ValidationProvider, extend, localize } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import Meta from "vue-meta";
import { loadVeeValidateExtend } from "./plugins/vee-validate";
import DashboardLayout from './components/DashboardLayout.vue';
import EmptyLayout from './components/EmptyLayout.vue';
import money from 'v-money'
import en from "vee-validate/dist/locale/en.json";
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
import vco from "v-click-outside";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(vco);
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
Vue.use(ElementUI);

localize("en", en);
Vue.use(BootstrapVue, {
  BFormDatepicker: {
    labelHelp: '',
    labelNoDateSelected: '',
    labelResetButton: 'リセット',
    hideHeader: true
  }
});
Vue.use(BootstrapVueIcons);
Vue.use(plugins);
Vue.use(Meta);
Vue.use(money, {precision: 0});
Vue.component('date-range-picker', DateRangePicker);
Vue.component('default-layout', DashboardLayout);
Vue.component('empty-layout', EmptyLayout);
Vue.component("v-select", vSelect);
loadVeeValidateExtend();
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.directive('clickoutside', {
  inserted: (el, binding, vnode) => {
// assign event to the element
    el.clickOutsideEvent = function (event) {
      // here we check if the click event is outside the element and it's children
      if (!(el === event.target || el.contains(event.target))) {
        // if clicked outside, call the provided method
        vnode.context[binding.expression](event)
      }
    }
// register click and touch events
    document.body.addEventListener('click', el.clickOutsideEvent)
    document.body.addEventListener('touchstart', el.clickOutsideEvent)
  },
  unbind: function (el) {
// unregister click and touch events before the element is unmounted
    document.body.removeEventListener('click', el.clickOutsideEvent)
    document.body.removeEventListener('touchstart', el.clickOutsideEvent)
  },
  stopProp(event) {
    event.stopPropagation()
  },
})
new Vue({
  router,
  store,
  ...App
}).$mount("#app");
