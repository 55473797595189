import * as api from "@/api/dashboard";
import service from "@/utils/axios";
import serviceNotification from '@/utils/axiosNotifications'

export const actions = {
  async getDashboardTime(_, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_DASHBOARD_TIME,
        method: "GET",
        params
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
  async getDashboardMessageToBot(_, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_DASHBOARD_MESSAGE_TO_BOT,
        method: "GET",
        params
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
  async getDashboardMessageNotReply(_, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_DASHBOARD_MESSAGE_UNANSWERED,
        method: "GET",
        params
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
  async getListDivision(_, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_LIST_DIVISION,
        method: "GET",
        params
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
  async getListPMs(_, id) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_PMS_LIST(id),
        method: "GET",
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
  async updateListPms(_, body) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_PMS_LIST(body.id),
        method: "PUT",
        data: body,
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
  async getNotification(_, params) {
    return new Promise((resolve, reject) => {
      serviceNotification({
        url: api.GET_DASHBOARD_NOTIFICATION,
        method: "GET",
        params
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },

  async updateRead(_, body) {
    return new Promise((resolve, reject) => {
      serviceNotification({
        url: api.UPDATE_READ,
        method: "PUT",
        data: body,
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
  async updateView(_, body) {
    return new Promise((resolve, reject) => {
      serviceNotification({
        url: api.UPDATE_VIEW,
        method: "PUT",
        data: body,
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
  async getTotalNotification(_, id) {
    return new Promise((resolve, reject) => {
      serviceNotification({
        url: api.GET_TOTAL_NOTIFICATION(id),
        method: "GET",
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
};