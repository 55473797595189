<template>
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M10 7.07153C11.8769 7.07153 13.3984 5.55 13.3984 3.6731C13.3984 1.79619 11.8769 0.274658 10 0.274658C8.12309 0.274658 6.60156 1.79619 6.60156 3.6731C6.60156 5.55 8.12309 7.07153 10 7.07153Z"
        fill="currentColor"/>
    <path
        d="M16.875 7.07154C18.0615 7.07154 19.0234 6.10965 19.0234 4.9231C19.0234 3.73655 18.0615 2.77466 16.875 2.77466C15.6885 2.77466 14.7266 3.73655 14.7266 4.9231C14.7266 6.10965 15.6885 7.07154 16.875 7.07154Z"
        fill="currentColor"/>
    <path
        d="M3.125 7.07154C4.31155 7.07154 5.27344 6.10965 5.27344 4.9231C5.27344 3.73655 4.31155 2.77466 3.125 2.77466C1.93845 2.77466 0.976562 3.73655 0.976562 4.9231C0.976562 6.10965 1.93845 7.07154 3.125 7.07154Z"
        fill="currentColor"/>
    <path
        d="M5.2418 9.00094C4.39609 8.30805 3.6302 8.39977 2.65234 8.39977C1.18984 8.39977 0 9.58258 0 11.0361V15.3021C0 15.9334 0.515234 16.4466 1.14883 16.4466C3.88422 16.4466 3.55469 16.4961 3.55469 16.3287C3.55469 13.3058 3.19664 11.089 5.2418 9.00094Z"
        fill="currentColor"/>
    <path
        d="M10.9301 8.41523C9.2221 8.27277 7.73753 8.41687 6.45702 9.47383C4.31417 11.1902 4.72655 13.5013 4.72655 16.3285C4.72655 17.0765 5.33515 17.6965 6.09452 17.6965C14.3399 17.6965 14.6681 17.9624 15.157 16.8797C15.3174 16.5135 15.2734 16.6299 15.2734 13.1269C15.2734 10.3447 12.8643 8.41523 10.9301 8.41523Z"
        fill="currentColor"/>
    <path
        d="M17.3476 8.39967C16.3644 8.39967 15.6027 8.30889 14.7582 9.00085C16.7881 11.0733 16.4453 13.1389 16.4453 16.3286C16.4453 16.4971 16.1717 16.4465 18.8101 16.4465C19.4664 16.4465 20 15.9149 20 15.2614V11.036C20 9.58249 18.8101 8.39967 17.3476 8.39967Z"
        fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  props: {
    size: Number || null
  }
};
</script>
