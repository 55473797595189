import * as api from "@/api/bots";
import service from "@/utils/axios";

export const actions = {
  async getMentionBotLogs(_, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_ALL_BOT_LOGS,
        method: "GET",
        params
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
  async getBotInfo(_, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_BOT_INFO,
        method: "GET",
        params
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
  async updateNgWord(_, body) {
    return new Promise((resolve, reject) => {
      service({
        url: api.UPDATE_NGWORDS,
        method: "PUT",
        data: body
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
  async botsLog(_, params) {
    return new Promise((resolve, reject) => {
      service({
        url: api.GET_ALL_BOT_LOGS,
        method: "GET",
        params
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
  async resolve(_, body) {
    return new Promise((resolve, reject) => {
      service({
        url: api.UPDATE_BOT_LOGS,
        method: "PUT",
        data: body
      }).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
};