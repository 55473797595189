<template>
  <div class="layout">
    <div class="header-nav">
      <!-- <ul class="nav justify-content-end">
        <li class="nav-item dropdown">
          <b-dropdown id="dropdown-right" right :text="user.name" class="nav-link text-body fw-bold">
            <b-dropdown-item @click="logOut">
              <LogoutIcon/>
              <span>Logout</span>
            </b-dropdown-item>
          </b-dropdown>
        </li>
      </ul> -->
      <div class="background-header">
        <div class="d-flex cover-logo">
          <router-link class="navbar-brand" :to="{ name: 'Home' }" exact>
            <img
              :src="require('@/assets/image/logo2.png')"
              alt="logo"
              class="logo"
            />
          </router-link>
          <h4>CTCW</h4>
        </div>
        <div class="cover-info">
          <img :src="user && user.avatar" alt="avatar" class="avatar" />
          <div v-click-outside="config" class="hd-noti">
            <img
              :src="require('@/assets/image/notification.svg')"
              alt="avatar"
              class="notification"
              @click="showNotification"
            />
            <div :class="getClassTotalNumber()">{{ getNumberTotal() }}</div>
            <div v-show="isShowNotification">
              <div class="cover_list_notification">
                <div class="head">
                  <div class="title">Thông báo</div>
                  <div class="seen" @click="seenAll">Xem tất cả</div>
                </div>
                <div class="notificationLayout">
                  <div class="tab">
                    <p class="titleTab">Hệ thống</p>
                    <div class="items">
                      <div
                        class="item"
                        :class="getClass(1)"
                        @click="setSystemId(1)"
                      >
                        <div class="cover-item-app">
                          <img
                            class="logoAcms"
                            :src="require('@/assets/image/acms-me.svg')"
                            alt="IconBo"
                          />
                          <div>ACMS ME</div>
                          <p class="description">Cổng nhân viên</p>
                        </div>
                        <div :class="getClassRed(totalDataMe)">
                          {{ getTotalitem(totalDataMe) }}
                        </div>
                      </div>
                      <div
                        class="item"
                        :class="getClass(2)"
                        @click="setSystemId(2)"
                      >
                        <div class="cover-item-app">
                          <img
                            class="logoAcms"
                            :src="require('@/assets/image/acms-bo.svg')"
                            alt="IconBo"
                          />
                          <div>ACMS BO</div>
                          <p class="description">Nhân sự</p>
                        </div>
                        <div :class="getClassRed(totalDataBO)">
                          {{ getTotalitem(totalDataBO) }}
                        </div>
                      </div>
                      <div
                        class="item"
                        :class="getClass(4)"
                        @click="setSystemId(4)"
                      >
                        <div class="cover-item-app">
                          <img
                            class="logoAcms"
                            :src="require('@/assets/image/acms-work-flow.svg')"
                            alt="IconBo"
                          />
                          <div>ACMS WorkFlow</div>
                          <p class="description">Tạo đề xuất</p>
                        </div>
                        <div :class="getClassRed(totalDataW)">
                          {{ getTotalitem(totalDataW) }}
                        </div>
                      </div>
                      <div
                        class="item"
                        :class="getClass(5)"
                        @click="setSystemId(5)"
                      >
                        <div class="cover-item-app">
                          <img
                            class="logoAcms"
                            :src="require('@/assets/image/acms-wiki.svg')"
                            alt="IconBo"
                          />
                          <div>ACMS Wiki</div>
                          <p class="description">CỔng thông tin</p>
                        </div>
                        <div :class="getClassRed(totalDataWiki)">
                          {{ getTotalitem(totalDataWiki) }}
                        </div>
                      </div>
                      <div
                        class="item"
                        :class="getClass(3)"
                        @click="setSystemId(3)"
                      >
                        <div class="cover-item-app">
                          <img
                            class="logoAcms"
                            :src="require('@/assets/image/acms-recruit.svg')"
                            alt="IconBo"
                          />
                          <div>ACMS Recruit</div>
                          <p class="description">Tuyển dụng</p>
                        </div>
                        <div :class="getClassRed(totalDataR)">
                          {{ getTotalitem(totalDataR) }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="contentNotification"
                    v-on:scroll="scrollDataNotification"
                    id="data-notification"
                  >
                    <div
                      class="infinite-scroll-component"
                      v-show="notifications.today.length > 0"
                    >
                      <p class="titleContent">Hôm nay</p>
                      <div class="contentNotificationItem">
                        <div
                          class="itemContent"
                          v-for="(notification, index) in notifications.today"
                          :key="index"
                          @click="redirectItem(notification)"
                        >
                          <div :class="getClassRead(notification)"></div>
                          <div class="infoNoti">
                            <img
                              class="logoNoti"
                              :src="
                                notification && notification.sender_avatar
                                  ? notification.sender_avatar
                                  : require('@/assets/image/avatar.svg')
                              "
                              @error="replaceImage"
                              alt="IconBo"
                            />
                            <div class="infoDetail">
                              <div class="contentNoti">
                                {{
                                  notification && notification.message
                                    ? truncate(notification.message, 35, "...")
                                    : ""
                                }}
                              </div>
                              <div class="date">
                                {{
                                  formatDate(
                                    notification && notification.created_at
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="infinite-scroll-component"
                      v-show="notifications.other.length > 0"
                    >
                      <p class="titleContent">Cũ hơn</p>
                      <div class="contentNotificationItem">
                        <div
                          class="itemContent"
                          v-for="(notification, index) in notifications.other"
                          :key="index"
                          @click="redirectItem(notification)"
                        >
                          <div :class="getClassRead(notification)"></div>
                          <div class="infoNoti">
                            <img
                              class="logoNoti"
                              :src="
                                notification && notification.sender_avatar
                                  ? notification.sender_avatar
                                  : require('@/assets/image/avatar.svg')
                              "
                              alt="IconBo"
                              @error="replaceImage"
                            />
                            <div class="infoDetail">
                              <div class="contentNoti">
                                {{
                                  notification && notification.message
                                    ? truncate(notification.message, 35, "...")
                                    : ""
                                }}
                              </div>
                              <div class="date">
                                {{
                                  formatDate(
                                    notification && notification.created_at
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-show="
                        notifications.other && notifications.other.length === 0
                      "
                      class="text-no-data"
                    >
                      Không có dữ liệu
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            :src="require('@/assets/image/iconSquare.svg')"
            alt="avatar"
            class="iconSquare"
            @click="showListApp"
            v-click-outside="awayNotify"
          />
          <img
            :src="require('@/assets/image/logout.svg')"
            alt="avatar"
            class="logout"
            @click="logOut"
          />
        </div>
        <div v-show="isShowListApp" class="cover_list_app">
          <div class="infoList">
            <div class="imgAvatar">
              <img :src="user && user.avatar" alt="avatar" class="avatar" />
            </div>
            <div class="infoUser">
              <div class="name">{{ user && user.name }}</div>
              <div class="email">{{ user && user.email }}</div>
              <div class="email">
                {{ user && user.position_name }} -
                {{ user && user.division_name }}
              </div>
            </div>
          </div>
          <div class="list_app">
            <div class="box">
              <a :href="bo" class="redirect" target="blank">
                <img
                  class="logoAcms"
                  :src="require('@/assets/image/acms-bo.svg')"
                  alt="IconBo"
                />
                <div>ACMS BO</div>
                <p class="description">Nhân sự</p>
              </a>
            </div>
            <div class="box">
              <a :href="me" class="redirect" target="blank">
                <img
                  class="logoAcms"
                  :src="require('@/assets/image/acms-me.svg')"
                  alt="IconBo"
                />
                <div>ACMS ME</div>
                <p class="description">Cổng nhân viên</p>
              </a>
            </div>
            <div class="box">
              <a :href="workFlow" class="redirect" target="blank">
                <img
                  class="logoAcms"
                  :src="require('@/assets/image/acms-work-flow.svg')"
                  alt="IconBo"
                />
                <div>ACMS WorkFlow</div>
                <p class="description">Tạo đề xuất</p>
              </a>
            </div>
            <div class="box">
              <a :href="wiki" class="redirect" target="blank">
                <img
                  class="logoAcms"
                  :src="require('@/assets/image/acms-wiki.svg')"
                  alt="IconBo"
                />
                <div>ACMS Wiki</div>
                <p class="description">CỔng thông tin</p>
              </a>
            </div>
            <div class="box">
              <a :href="recruit" class="redirect" target="blank">
                <img
                  class="logoAcms"
                  :src="require('@/assets/image/acms-recruit.svg')"
                  alt="IconBo"
                />
                <div>ACMS Recruit</div>
                <p class="description">Tuyển dụng</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav id="sidebar" class="col sidebar">
      <ul class="nav flex-column vertical-nav nav-bar">
        <router-link
          tag="li"
          class="nav-item"
          :class="isSelectedOverView ? 'active' : ''"
          :to="{ name: 'Home' }"
          @click.native="expandMenu('overview')"
          exact
        >
          <DashboardIcon />
          <span>Overview</span>
        </router-link>
        <router-link
          tag="li"
          class="nav-item"
          :class="isSelectedProjectView ? 'active' : ''"
          :to="{ name: 'ListProject' }"
          @click.native="expandMenu('projects')"
          exact
        >
          <UserIcon />
          <span>List Project</span>
        </router-link>
        <li
          :class="
            isExpandSetting ? 'nav-item sub-menu active' : 'nav-item sub-menu'
          "
          @click="expandMenu('settings')"
          id="setting-menu"
          v-show="
            user.roleInSystem == 'Owner' ||
            user.roleInSystem == 'SystemAdmin' ||
            user.roleInSystem == 'DLeadOrSubDlead'
          "
        >
          <a>
            <RevenueIcon />
            <span class="span-icon">Setting</span>
            <ArrowDownBold
              class="icon right arrow-icon"
              v-if="!isExpandSetting"
            />
            <ArrowUpBold class="icon right arrow-icon" v-if="isExpandSetting" />
          </a>
          <ul class="child-item">
            <router-link
              tag="li"
              :to="{ name: 'Division' }"
              :class="isSettingDivision ? 'active' : ''"
              @click.native="onClickSub($event, 'isSettingDivision')"
              id="revenue-system"
              >Division
            </router-link>
            <router-link
              tag="li"
              :to="{ name: 'Bot' }"
              :class="isSettingBot ? 'active' : ''"
              @click.native="onClickSub($event, 'isSettingBot')"
              id="revenue-user"
              v-show="
                user.roleInSystem == 'Owner' ||
                user.roleInSystem == 'SystemAdmin'
              "
              >Bot
            </router-link>
          </ul>
        </li>
      </ul>
    </nav>
    <div class="main background-f6f9fa">
      <slot />
    </div>
  </div>
</template>

<script>
import DashboardIcon from "@/components/Icon/DashboardIcon";
import UserIcon from "@/components/Icon/UserIcon";
import RevenueIcon from "@/components/Icon/RevenueIcon";
import { mapGetters } from "vuex";
import ArrowDownBold from "@/components/Icon/ArrowDownBold";
import ArrowUpBold from "@/components/Icon/ArrowUpBold";
import VueCookies from "vue-cookies";

export default {
  components: {
    ArrowUpBold,
    ArrowDownBold,
    DashboardIcon,
    UserIcon,
    RevenueIcon,
  },
  data() {
    return {
      isSelectedOverView: true,
      isExpandSetting: false,
      isSelectedProjectView: false,
      isSettingBot: false,
      isSettingDivision: false,
      isShowListApp: false,
      bo: process && process.env && process.env.VUE_APP_MY_ENV_BASE_URL_BO,
      me: process && process.env && process.env.VUE_APP_MY_ENV_BASE_URL_ME,
      workFlow:
        process && process.env && process.env.VUE_APP_MY_ENV_BASE_URL_WORKFLOW,
      wiki: process && process.env && process.env.VUE_APP_MY_ENV_BASE_URL_WIKI,
      recruit:
        process && process.env && process.env.VUE_APP_MY_ENV_BASE_URL_RECRUIT,
      isShowNotification: false,
      config: {
        handler: this.awayNotifyCation,
        middleware: this.middleware,
        events: ["dblclick", "click"],
      },
      notifications: {
        other: [],
        today: [],
      },
      notificationScroll: [],
      params: {
        user_id: null,
        system_id: 1,
        page: 1,
        size: 20,
      },
      systemId: 1,
      today: [],
      other: [],
      totalDataMe: null,
      totalDataBO: null,
      totalDataW: null,
      totalDataWiki: null,
      totalDataR: null,
      numberNoti: 0,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  created() {
    const path = this.$route.path;
    const tmp = path.split("/");
    this.expandMenu(tmp[1]);
    this.getUserInfor();
  },
  mounted() {
    this.getTotalNoti();
  },
  methods: {
    getClassTotalNumber() {
      if (this.numberNoti > 0) {
        return "numberUnRead";
      }
    },
    getNumberTotal() {
      if (this.numberNoti <= 99 && this.numberNoti > 0) {
        return this.numberNoti;
      }
      if (this.numberNoti > 99) {
        return "99+";
      }
    },
    getClassRed(item) {
      if (item && item.total > 0) {
        return "bg-red";
      }
    },
    getTotalitem(item) {
      if (item && item.total <= 99) {
        return item.total;
      }
      if (item && item.total > 99) {
        return "99+";
      }
    },
    replaceImage(error) {
      error.target.src = require("@/assets/image/avatar.svg");
    },
    getClassRead(notification) {
      if (notification.is_read === 0) {
        return "read";
      } else {
        return "unRead";
      }
    },
    getClass(item) {
      if (item === 1 && this.systemId === 1) {
        return "activeItem";
      }
      if (item === 2 && this.systemId === 2) {
        return "activeItem";
      }
      if (item === 4 && this.systemId === 4) {
        return "activeItem";
      }
      if (item === 5 && this.systemId === 5) {
        return "activeItem";
      }
      if (item === 3 && this.systemId === 3) {
        return "activeItem";
      }
    },
    setSystemId(item) {
      this.notifications = {
        other: [],
        today: [],
      };
      this.today = [];
      this.other = [];
      if (item === 1) {
        this.systemId = 1;
        this.params.system_id = 1;
        this.params.page = 1;
        this.numberNoti -= this.totalDataMe;
        this.totalDataMe = 0;
      }
      if (item === 2) {
        this.systemId = 2;
        this.params.system_id = 2;
        this.params.page = 1;
        this.numberNoti -= this.totalDataBO;
        this.totalDataBO = 0;
      }
      if (item === 4) {
        this.systemId = 4;
        this.params.system_id = 4;
        this.params.page = 1;
        this.numberNoti -= this.totalDataW;
        this.totalDataW = 0;
      }
      if (item === 5) {
        this.systemId = 5;
        this.params.system_id = 5;
        this.params.page = 1;
        this.numberNoti -= this.totalDataWiki;
        this.totalDataWiki = 0;
      }
      if (item === 3) {
        this.systemId = 3;
        this.params.system_id = 3;
        this.params.page = 1;
        this.numberNoti -= this.totalDataR;
        this.totalDataR = 0;
      }
      this.seenAllItem();
      this.getNotifications();
    },
    scrollDataNotification() {
      var scrollNotification = document.getElementById("data-notification");
      if (
        Math.round(scrollNotification.scrollTop) +
          scrollNotification.offsetHeight ===
        scrollNotification.scrollHeight
      ) {
        if (this.notificationScroll.length > 0) {
          this.params.page += 1;
          this.getNotifications();
        }
      }
    },
    formatDate(date) {
      if (!date) {
        return "";
      }
      return this.$dayjs(date).format("HH:mm YYYY-MM-DD");
    },
    truncate(text, length, suffix) {
      if (text && text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
    awayNotify() {
      this.isShowListApp = false;
    },
    showListApp() {
      this.isShowListApp = !this.isShowListApp;
    },
    awayNotifyCation() {
      this.isShowNotification = false;
    },
    showNotification() {
      this.isShowNotification = !this.isShowNotification;
      this.getNotifications();
    },
    middleware() {
      return true;
    },
    expandMenu(menuParent) {
      switch (menuParent) {
        case "overview":
          this.isSelectedOverView = true;
          this.isSelectedProjectView = false;
          this.isExpandSetting = false;
          this.isSettingDivision = false;
          this.isSettingBot = false;
          break;
        case "projects":
          this.isSelectedProjectView = true;
          this.isSelectedOverView = false;
          this.isExpandSetting = false;
          this.isSettingDivision = false;
          this.isSettingBot = false;
          break;
        case "settings":
          this.isSelectedProjectView = false;
          this.isSelectedOverView = false;
          this.isExpandSetting = false;
          this.isExpandSetting = !this.isExpandSetting;
          break;
        default:
          this.isExpandSetting = false;
      }
    },
    async logOut() {
      this.$store
        .dispatch("user/logout", {
          token: VueCookies.get("token"),
        })
        .then(() => {
          VueCookies.remove("token");
          window.location.href = process.env.VUE_APP_ACMS_URL;
        });
    },
    onClickSub(event, name) {
      event.stopPropagation();
      this.isSettingDivision = false;
      this.isSettingBot = false;
      this.isExpandSetting = true;
      this[name] = true;
    },
    async getUserInfor() {
      this.$store.dispatch("auth/getInfoUser");
    },
    async getNotifications() {
      this.params.user_id = this.user.user_id;
      this.$store
        .dispatch("dashboard/getNotification", this.params)
        .then((response) => {
          response.list_noti.map((element) => {
            if (
              this.$dayjs(element.created_at).format("YYYY-MM-DD") ===
              this.$dayjs().format("YYYY-MM-DD")
            ) {
              this.today.push(element);
            } else {
              this.other.push(element);
            }
          });
          this.notificationScroll.push(...response.list_noti);
          this.notifications.today = this.today;
          this.notifications.other = this.other;
        })
        .catch(() => {});
    },
    getUrlNotificationToWiki(type, notification) {
      const WIKI_URL = process.env.VUE_APP_MY_ENV_BASE_URL_WIKI;
      const {
        group_id: groupId,
        post_id: postId,
        series_id: seriesId,
      } = notification;
      switch (type) {
        case 3:
        case 5:
          if (groupId) {
            return WIKI_URL + `/group/detail-group/${groupId}/${postId}`;
          }
          return WIKI_URL + `/home/detail-post/${postId}`;
        case 10:
          if (groupId) {
            return (
              WIKI_URL +
              `/group/detail-group/${groupId}/${postId}?idNotice=${this.getIdCommentParent(
                notification
              )}&idScroll=${this.getIdScrollTo(notification)}`
            );
          }
          return (
            WIKI_URL +
            `/home/detail-post/${postId}?idNotice=${this.getIdCommentParent(
              notification
            )}&idScroll=${this.getIdScrollTo(notification)}`
          );
        case 4:
          return WIKI_URL + `/group/classification/${groupId}`;
        case 6:
          if (groupId) {
            return WIKI_URL + `/group/list-series/${groupId}/${seriesId}`;
          }
          return WIKI_URL + `/home/list-series/${seriesId}`;
        case 7:
          if (groupId) {
            return WIKI_URL + `/group/user-manage-group/${groupId}?tab=3`;
          }
          break;
        case 8:
          if (groupId) {
            return WIKI_URL + `/group/user-manage-group/${groupId}?tab=1`;
          }
          break;
        case 9:
          if (groupId) {
            return WIKI_URL + `/group/user-manage-group/${groupId}?tab=2`;
          }
          break;
        default:
          return "";
      }
    },
    getIdScrollTo(item) {
      if (!item.comment) {
        return;
      }
      const postComment = JSON.parse(item.comment);
      return postComment.id || postComment.parent_id || postComment.grand_id;
    },
    getIdCommentParent(item) {
      if (!item.comment) {
        return;
      }
      const postComment = JSON.parse(item.comment);
      return postComment.grand_id || postComment.parent_id || postComment.id;
    },
    async getTotalNoti() {
      this.$store
        .dispatch("dashboard/getTotalNotification", this.user.user_id)
        .then((response) => {
          this.totalDataMe = response.find((item) => item.system_id === 1);
          this.totalDataBO = response.find((item) => item.system_id === 2);
          this.totalDataW = response.find((item) => item.system_id === 4);
          this.totalDataWiki = response.find((item) => item.system_id === 5);
          this.totalDataR = response.find((item) => item.system_id === 3);
          if (this.totalDataMe) {
            this.numberNoti += this.totalDataMe.total;
          }
          if (this.totalDataBO) {
            this.numberNoti += this.totalDataBO.total;
          }
          if (this.totalDataW) {
            this.numberNoti += this.totalDataW.total;
          }
          if (this.totalDataWiki) {
            this.numberNoti += this.totalDataWiki.total;
          }
          if (this.totalDataR) {
            this.numberNoti += this.totalDataR.total;
          }
        })
        .catch((e) => {
          console.log("e", e);
        });
    },
    async redirectItem(item) {
      const paramsItem = {
        id: item.id,
        user_id: this.user.user_id,
      };
      this.$store
        .dispatch("dashboard/updateRead", paramsItem)
        .then(() => {
          item.is_read = 1;
          switch (this.systemId) {
            case 1:
              return window.open(
                process.env.VUE_APP_MY_ENV_BASE_URL_ME +
                  "/timekeeping/time-sheets",
                "_blank"
              );
            case 2:
              return this.getUrlNotificationToWiki(item.type, item);
            case 4:
              return window.open(
                process.env.VUE_APP_MY_ENV_BASE_URL_WORKFLOW +
                  "/request?request_id=" +
                  item.request_id,
                "_blank"
              );
            case 5:
              return this.getUrlNotificationToRecruit(item.type, item);
            default:
              return null;
          }
        })
        .catch((e) => {
          console.log("e", e);
        });
    },

    getUrlNotificationToRecruit(type, notification) {
      let detail = JSON.parse(notification.detail);
      if (!detail) detail = {};
      const {
        interview_date,
        interview_id,
        job_id,
        order_id,
        candidate_id,
        apply_id,
        kpi_id,
      } = detail;
      switch (type) {
        case 11:
          return `/admin/candidate-detail/${candidate_id}/apply-detail/${apply_id}`;
        case 12:
          return `/admin/interview-schedule/${interview_date}/${interview_id}`;
        case 13:
          return `/admin/interview-schedule`;
        case 14:
          return `/admin/manager-order-leader/${order_id}`;
        case 15:
          return `/admin/manager-job/${job_id}`;
        case 16:
          return `/admin/manager-order-hr/${order_id}`;
        case 17:
          return `/admin/hr/candidate-detail/${candidate_id}/apply-detail/${apply_id}`;
        case 18:
          return `/admin/hr/candidate-detail/${candidate_id}/apply-detail/${apply_id}/test`;
        case 21:
          return `/admin/manager-kpi`;
        case 22:
          return `/admin/manager-kpi/${kpi_id}`;
        case 23:
          return `/admin/list-referral-bonus`;
        case 24:
          return `/admin/list-cost-recruit`;
        case 25:
          return `/admin/hr/candidate-detail/${candidate_id}`;
        default:
          return "";
      }
    },
    async seenAllItem() {
      const paramsItem = {
        system_id: this.params.system_id,
        user_id: this.user.user_id,
      };
      this.notifications = {
        other: [],
        today: [],
      };
      this.$store
        .dispatch("dashboard/updateView", paramsItem)
        .then(() => {
          this.params.page = 1;
        })
        .catch((e) => {
          console.log("e", e);
        });
    },

    async seenAll() {
      const paramsItem = {
        user_id: this.user.user_id,
      };
      this.notifications = {
        other: [],
        today: [],
      };
      this.$store
        .dispatch("dashboard/updateView", paramsItem)
        .then(() => {
          this.params.page = 1;
          this.getNotifications();
        })
        .catch((e) => {
          console.log("e", e);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/layout.scss";
.info {
  display: flex;
  justify-content: end;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  height: 100%;
}
.cover-item-app {
  width: 80%;
}
.bg-red {
  background: #d83c2c;
  margin-left: auto;
  margin-right: 10px;
  height: 22px;
  width: 22px;
  text-align: center;
  padding-top: 3px;
  border-radius: 50%;
  font-size: 10px;
  margin-top: 11px;
  color: #fff;
  padding-top: 0px;
}
.background-header {
  background-color: #222433;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  width: 100%;
  align-items: center;
  height: 100%;
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    color: #fff;
    background-color: #adb5bd;
    display: inline-flex;
    justify-content: center;
    margin-right: 17px;
  }
  .notification {
    height: 25px;
    margin-right: 15px;
    cursor: pointer;
  }
  .iconSquare {
    height: 25px;
    margin-right: 8px;
    cursor: pointer;
  }
  .logout {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-right: 10px;
  }
}
.cover_list_app {
  width: 635px;
  opacity: 1;
  right: 1%;
  top: 100% !important;
  transform: unset !important;
  visibility: visible;
  transition: all 0.5s ease-in-out;
  max-height: 600px;
  overflow-y: auto !important;
  background-color: rgb(245, 245, 249);
  position: absolute;
  height: 500px;
  .infoList {
    display: flex;
    padding: 30px 10px 20px;
    border-bottom: 1px solid #e7e7e7;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 100%;
  }
  .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
  }
  .name {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #223354;
  }
  .email {
    font-size: 12px;
    line-height: 20px;
    color: #8d8d8d;
    display: flex;
    align-items: center;
  }
  .infoUser {
    margin-left: 5px;
  }
  .list_app {
    flex-basis: 100%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 100%;
    flex-wrap: wrap;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
  }
  .box {
    cursor: pointer;
    width: 25%;
    height: 120px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    display: inline-block;
    transition: all 0.2s ease-out;
    text-align: center;
    margin: 10px 0 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #223354;
    .redirect {
      text-decoration: none;
      color: #223354;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
    }
    .logoAcms {
      width: 50px;
      height: 50px;
      margin-top: 10px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .description {
      font-size: 12px;
      line-height: 20px;
      color: #8d8d8d;
      margin: 4px 0 0;
    }
  }
}
.cover_list_notification {
  width: 736px;
  opacity: 1;
  right: -150%;
  top: 159% !important;
  transform: unset !important;
  visibility: visible;
  transition: all 0.5s ease-in-out;
  overflow-y: auto !important;
  position: absolute;
  .head {
    display: flex;
    justify-content: space-between;
    background: #f9fbfc;
    align-items: center;
  }
  .title {
    padding: 20px;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #223354;
  }
  .seen {
    color: #2c8ce4 !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    padding-right: 20px;
    cursor: pointer;
  }
}
.notificationLayout {
  display: flex;
  background: #f9fbfc;
  .item {
    display: flex;
    text-decoration: none;
    color: #223354;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    justify-content: space-around;
    text-align: center;
    align-items: center;
  }
  .item:hover {
    background: #e8f0f9;
    border-radius: 10px;
  }
  .logoAcms {
    width: 50px;
    height: 50px;
    margin-top: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .description {
    font-size: 12px;
    line-height: 20px;
    color: #8d8d8d;
    margin: 4px 0 0;
  }
  .tab {
    background: #f3f3f4;
    width: 35%;
    height: 676px;
    border-bottom-left-radius: 4px;
  }
  .titleTab {
    color: #223354;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    padding: 13px 0 20px 20px;
  }
}
.contentNotification {
  width: 65%;
  padding: 15px;
  overflow: auto;
  max-height: 676px;
  margin: 0;
}
.titleContent {
  color: #223354;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 14px;
  display: flex;
}
.itemContent {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px dashed #e0e0e0;
  cursor: pointer;
}
.itemContent:hover {
  background-color: #f3f3f4;
  border-radius: 5px;
}
.contentNotificationItem {
  text-decoration: none;
  .infoNoti {
    display: flex;
  }
  .infoDetail {
    margin-left: 5px;
    .contentNoti {
      color: #464646;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.read {
  height: 10px;
  width: 10px;
  background: #fea628;
  border-radius: 50%;
  margin-right: 6px;
}
.unRead {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 6px;
}
.date {
  display: flex;
  color: #909090;
  font-weight: 400;
  font-size: 14px;
}
.active {
  border-radius: 10px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
}
.logoNoti {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
.text-no-data {
  color: #e74c3c;
  margin-bottom: 0;
  font-size: 14px;
  text-align: center;
}
.contentNotification::-webkit-scrollbar {
  width: 5px;
}
.contentNotification::-webkit-scrollbar-thumb {
  background: #888888;
}
.cover-info {
  display: flex;
  align-items: center;
}
.cover-logo {
  img {
    vertical-align: middle;
    border-style: none;
  }
  h4 {
    margin-left: 8px;
    margin-right: 20px;
    margin-bottom: 0;
    font-size: 25px;
    line-height: 32px;
    text-transform: uppercase;
    color: #f9f9f9 !important;
    text-align: center;
    display: flex;
    align-items: center;
  }
}
.box:hover {
  background: rgba(92, 59, 59, 0.101961);
}
.activeItem {
  background: #e8f0f9;
  border-radius: 10px;
}
.numberUnRead {
  position: absolute;
  background-color: #e74c3c;
  height: 21px;
  width: 23px;
  border-radius: 50%;
  font-size: 9px;
  line-height: 19px;
  top: -6px;
  right: 10px;
  border: 1px solid #000;
  color: #fff;
}
.hd-noti {
  position: relative;
}
</style>
